


import React from 'react';
import { Box, Flex, Heading, Text, Image, Button, Grid} from '@chakra-ui/react';
import { FaLightbulb, FaUser, FaToilet, FaTint, FaShower, FaTools, FaAccessibleIcon } from 'react-icons/fa';
import productImage from "../assets/egresan-home.png";

const features = [
  {
    icon: FaLightbulb,
    feature: 'Touchless Operation',
    text: 'Advanced sensors for a completely touchless experience',
  },
  {
    icon: FaUser,
    feature: 'Personalized User Profiles',
    text: 'Customized settings for individual comfort',
  },
  {
    icon: FaToilet,
    feature: 'Smart Lid and Seat',
    text: 'Automatic opening and closing mechanisms',
  },
  {
    icon: FaTint,
    feature: 'Intelligent Flushing System',
    text: 'Adapts to user needs to reduce water wastage',
  },
  {
    icon: FaShower,
    feature: 'Built-in Bidet and Air Drying',
    text: 'Adjustable water pressure and temperature',
  },
  {
    icon: FaTools,
    feature: 'Automated Cleaning and Maintenance',
    text: 'Self-cleaning capabilities and maintenance alerts',
  },
  {
    icon: FaAccessibleIcon,
    feature: 'Accessibility Features',
    text: 'Inclusive design for people with disabilities',
  },
];

const FeatureItem = ({ icon, feature, text }) => (
  <Box bg="white" p={4} borderRadius="md" boxShadow="md">
    <Box as={icon} size="24px" color="blue.500" mb={4} mx="auto" />
    <Heading as="h4" size="md" mb={2} color="blue.800">
      {feature}
    </Heading>
    <Text fontSize="md">{text}</Text>
  </Box>
);

const ProductDetails = () => {
  return (
    <>
      <Box py="10" p={2} bgGradient="linear(to bottom, #f3f8ff, #a5c9fb)" position="relative" mt="4">
        {/* Lines */}
        {/* Product Details Heading */}
        <Heading as="h2" size="xl" textAlign="center" mb="8" mt={8} color="blue.800" marginTop={8}>
          Product Details
        </Heading>
        <Flex justifyContent="center" alignItems="center" flexDirection={{ base: 'column', md: 'row' }} mb="4">
          <Box maxW={{ base: '100%', md: '40%' }}>
            <Image
              src={productImage}
              alt="Automated Vision Toilet"
              w="100%"
              h="300px"
              borderRadius="8px"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
              mb="4"
            />
          </Box>
          <Box maxW={{ base: '100%', md: '60%' }} ml={{ base: 0, md: '8' }}>
            <Text fontSize={{ base: 'lg', md: 'xl' }} mb="4">
              With the Automated Vision Toilet, we aim to elevate the restroom experience for individuals, businesses,
              and society as a whole. This futuristic fixture exemplifies our commitment to innovation, sustainability,
              and user-centric design, setting the benchmark for smart restrooms of the future. At EGRESAN, we are proud
              to contribute to a cleaner, safer, and more convenient world, one restroom at a time.
            </Text>
          </Box>
        </Flex>
      </Box>

        {/* Features Section */}
        <Box py="8" bg="blue.100" textAlign="center" color="blue.800" overflowX="hidden" mt="4" p={{base:"4", md:"8"}}>
          <Heading as="h3" size="lg" mb="4" fontFamily="heading" fontWeight="bold">
            Key Features
          </Heading>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6} pt="4" pb="4">
          {features.map((feature, index) => (
          <FeatureItem key={index} icon={feature.icon} feature={feature.feature} text={feature.text} />
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ProductDetails;
