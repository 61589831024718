import React from 'react';
import { Box, Flex, Heading, Text, Input, Button, Link } from '@chakra-ui/react';
import { FaEnvelope, FaPhone, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'; // Import Font Awesome icons

const Contact = () => {
  const handleSendEmail = () => {
    const email = 'egresanauttogoes@gmail.com';
    window.location.href = `mailto:${email}`;
  };

  return (
    <>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Box
            bg="blue.50" // Light blue background
            p="8"
            color="blue.800"
            textAlign="center"
            borderRadius="lg"
            boxShadow="md"
            mt="4"
            mx="4"
          >
            <Heading as="h2" size="xl" mb="4" fontFamily="heading" fontWeight="bold">
              Contact Us
            </Heading>
            <Text fontSize={{ base: 'md', md: 'lg' }} lineHeight={{ base: '1.6', md: '1.8' }}>
              Feel free to get in touch with us! For any inquiries or feedback, please use the direct email
              feature below or fill out the contact form. We will get back to you as soon as possible.
            </Text>
        </Box>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
          mt="4"
          mx="4"
        >
          <Flex
            flexDirection="column"
          >
          <Box
            bg="blue.200" // Light blue background
            p="8"
            color="blue.800"
            textAlign="center"
            borderRadius="lg"
            boxShadow="md"
            mt="4"
            mx="4"
            mb="4"
          >
            <Heading as="h3" size="lg" mb="4" fontFamily="heading" fontWeight="bold">
              Direct Email
            </Heading>
            <Box display="flex" alignItems="center" justifyContent="center" mb="2">
              <Box as={FaEnvelope} size="24px" color="blue.800" mr="2" />
              <Link href="mailto:egresanauttogoes@gmail.com" color="blue.800">
                egresanauttogoes@gmail.com
              </Link>
            </Box>
            <Button colorScheme="blue" size="lg" onClick={handleSendEmail}>
              Send Email
            </Button>
          </Box>
          <Box
            bg="blue.200" // Light blue background
            p="8"
            color="blue.800"
            textAlign="center"
            borderRadius="lg"
            boxShadow="md"
            flex="1"
            mb={{ base: '4', md: '4' }}
          >
            <Heading as="h3" size="lg" mb="4" fontFamily="heading" fontWeight="bold">
              Other Contact Details
            </Heading>
            <Flex flexDirection="column" alignItems="center" justifyContent="center">
              <Box display="flex" alignItems="center" justifyContent="center" mb="2">
                <Box as={FaPhone} size="24px" color="blue.800" mr="2" />
                <Link href="tel:+1234567890" color="blue.800">
                  +91 8554965731
                </Link>
              </Box>
              <Flex justifyContent="center" mb="2">
                <Link href="#" color="blue.800" mx="2">
                  <Box as={FaFacebook} size="32px" color="blue.800" />
                </Link>
                <Link href="#" color="blue.800" mx="2">
                  <Box as={FaTwitter} size="32px" color="blue.800" />
                </Link>
                <Link href="#" color="blue.800" mx="2">
                  <Box as={FaInstagram} size="32px" color="blue.800" />
                </Link>
              </Flex>
            </Flex>
          </Box>
          </Flex>

          <Box
            bg="blue.300" // Light blue background
            p="8"
            color="blue.800"
            textAlign="center"
            borderRadius="lg"
            boxShadow="md"
            flex="1"
            ml={{ base: '0', md: '4' }}
            mb="4"
          >
            <Heading as="h3" size="lg" mb="4" fontFamily="heading" fontWeight="bold">
              Send Us a Message
            </Heading>
            <form>
              <Flex flexDirection="column" alignItems="center" justifyContent="center">
                <Input type="text" placeholder="Your Name" size="lg" mb="4" />
                <Input type="tel" placeholder="Your Phone Number" size="lg" mb="4" />
                <Input type="email" placeholder="Your Email" size="lg" mb="4" />
                <textarea
                  placeholder="Your Message"
                  rows="4"
                  resize="none"
                  style={{
                    width: '100%',
                    borderRadius: 'lg',
                    border: '1px solid #cbd5e0',
                    padding: '8px',
                    fontSize: '16px',
                    fontFamily: 'body',
                    color: 'blue.800',
                    marginBottom: '16px',
                  }}
                />
                <Button colorScheme="blue" size="lg" onClick={() => {}}>
                  Send Message
                </Button>
              </Flex>
            </form>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default Contact;
