// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
// import { ChakraProvider } from '@chakra-ui/react';
// import { BrowserRouter } from 'react-router-dom';
// import App from './App';
// import WavePattern from './components/WavePattern';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <ChakraProvider>
//       <BrowserRouter>
//         <App/>
//       </BrowserRouter>
//     </ChakraProvider> 
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// index.js or App.js
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ChakraProvider, Box, CSSReset } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import { ColorModeScript } from '@chakra-ui/react';
import ReactDOM from 'react-dom';
import App from './App';
import WavePattern from './components/WavePattern';

// Custom theme (if needed)
const theme = extendTheme({
  // Your custom theme configuration here...
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript />
      <CSSReset />
      <Box position="relative">
        <WavePattern />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Box>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
