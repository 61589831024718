import React from 'react';
import { Box, Flex, Heading, Text, Image, Grid, GridItem } from '@chakra-ui/react';
import { FaLightbulb, FaHandsHelping, FaUserFriends, FaGlobeAsia,FaUserTie } from 'react-icons/fa'; // Replace with your own icons

const About = () => {
  return (
    <>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        {/* About Us */}
        <Box
          bg="blue.50" // Light blue background
          p="8"
          color="blue.800"
          textAlign="center"
          borderRadius="lg"
          boxShadow="md"
          mt="4"
          mx="4"
        >
          <Heading as="h2" size="xl" mb="4" fontFamily="heading" fontWeight="bold">
            About EGRESAN
          </Heading>
          <Text fontSize={{ base: 'md', md: 'lg' }} lineHeight={{ base: '1.6', md: '1.8' }}>
            EGRESAN is on a mission to revolutionize public washrooms with innovative and eco-friendly solutions. We
            understand the challenges faced by users in traditional restrooms and are dedicated to delivering a
            refreshing, safe, and private experience for all. Our cutting-edge technology aims to set new standards in
            restroom hygiene and accessibility.
          </Text>
        </Box>

        {/* Our Values */}
        <Box
          bg="blue.100" // Light blue background
          p="8"
          color="blue.800"
          textAlign="center"
          borderRadius="lg"
          boxShadow="md"
          mt="4"
          mx="4"
        >
          <Heading as="h2" size="xl" mb="4" fontFamily="heading" fontWeight="bold">
            Our Values
          </Heading>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={6}>
            <ValueItem icon={FaLightbulb} title="Innovation" description="We embrace creativity and continuously innovate to provide the best restroom experience." />
            <ValueItem icon={FaHandsHelping} title="Community" description="We care about our community and work towards creating safe and inclusive spaces for everyone." />
            <ValueItem icon={FaUserFriends} title="Customer-Centric" description="Our customers are at the heart of everything we do, and we strive to exceed their expectations." />
            <ValueItem icon={FaGlobeAsia} title="Sustainability" description="Environmental responsibility is crucial, and we design solutions that are eco-friendly and efficient." />
            <ValueItem icon={FaLightbulb} title="Quality" description="We are committed to delivering products and services of the highest quality and reliability." />
            <ValueItem icon={FaHandsHelping} title="Integrity" description="We operate with honesty, transparency, and respect for our customers and stakeholders." />
          </Grid>
        </Box>

        {/* Our Team */}
        <Box
          bg="blue.200"
          p="8"
          color="blue.800"
          textAlign="center"
          borderRadius="lg"
          boxShadow="md"
          mt="4"
          mx="4"
        >
          <Heading as="h2" size="xl" mb="4" fontFamily="heading" fontWeight="bold">
            Our Team
          </Heading>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }} gap={4}>
            <TeamMember name="Sanjeevani Gondane" role="Founder and CEO" />
            <TeamMember name="Narendra Tidke" role="Director" />
            <TeamMember name="Alok Pathak" role="Operation Head" />
            <TeamMember name="Avinash Kumar" role="Software Engineer" />
            <TeamMember name="Sushil Umredkar" role="Automation Head" />
          </Grid>
        </Box>
      </Flex>
    </>
  );
};

const ValueItem = ({ icon, title, description }) => {
  return (
    <Box mt="4">
      <Box as={icon} size="48px" color="blue.800" mx="auto" />
      <Heading as="h3" size="md" mt="2" fontFamily="heading" fontWeight="bold">
        {title}
      </Heading>
      <Text fontSize="md" lineHeight="1.6" mt="2">
        {description}
      </Text>
    </Box>
  );
};

const TeamMember = ({ name, role, expertise }) => {
  return (
    <Box
      p="4"
      borderWidth="1px"
      borderRadius="lg"
      borderColor="gray.200"
      boxShadow="md"
      textAlign="center"
      mt="4"
    >
      <Box display="flex" alignItems="center" justifyContent="center" mb="2">
        <Box as={FaUserTie} size="48px" color="blue.800" textAlign="center" />
      </Box>
      <Text fontSize="lg" fontWeight="bold">
        {name}
      </Text>
      <Text fontSize="md" color="gray.600">
        {role}
      </Text>
    </Box>
  );
};

export default About;
