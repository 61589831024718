import React from 'react';
import { Box, Flex, Text, Link, Icon, Image } from '@chakra-ui/react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import logo from "../assets/egresan-logo.png"

const Footer = () => {
  return (
    <Box bg="blue.500" color="white" py="4" px={{ base: '4', md: '8' }}>
      <Flex direction={{ base: 'column', md: 'row' }} justifyContent={{ base: 'center', md: 'space-between' }} alignItems={{ base: 'center', md: 'flex-start' }}>
        <Box mb={{ base: '4', md: '0' }}>
          <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
            EGRESAN Auttogoes Pvt LTD
          </Text>
          <Text fontSize={{ base: 'sm', md: 'md' }}>Address: 123 Main Street, City, Country</Text>
          <Text fontSize={{ base: 'sm', md: 'md' }}>Email: egresanauttogoes@gmail.com</Text>
          <Text fontSize={{ base: 'sm', md: 'md' }}>Phone: +91 8554965731</Text>
        </Box>
        <Flex alignItems="center" mt={{ base: '4', md: '0' }}>
          <Link href="#" mr="4">
            <Icon as={FaFacebook} boxSize={{ base: '5', md: '6' }} />
          </Link>
          <Link href="#" mr="4">
            <Icon as={FaTwitter} boxSize={{ base: '5', md: '6' }} />
          </Link>
          <Link href="#" mr="4">
            <Icon as={FaInstagram} boxSize={{ base: '5', md: '6' }} />
          </Link>
          {/* Add your website logo here */}
          <Image src={logo} alt="Website Logo" w={{ base: '120px', md: '150px' }} h={{ base: '40px', md: '50px' }} borderRadius="1rem" />
        </Flex>
      </Flex>
      <Text mt="4" textAlign="center" fontSize={{ base: 'sm', md: 'md' }}>
        &copy; 2023 EGRESAN Auttogoes Pvt LTD. All rights reserved.
      </Text>
    </Box>
  );
};

export default Footer;
