import React from 'react';
import AllRoutes from './AllRoutes';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  return (
    <>
        <Navbar/>
        <AllRoutes/>
        <Footer/>
    </>
  );
}

export default App;




