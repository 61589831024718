import React, {useState} from 'react';

import { Box, Flex, Heading, Text, Image, Button, Collapse } from '@chakra-ui/react';
import heroImage from '../../assets/egresan-hero.png'; 

 
const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box>
      {/* Hero Section */}
      <Box bgImage={`url(${heroImage})`} bgSize="cover" bgPosition="center" h={{base:"600px",md:"550px"}} position="relative" p="4">
        <Box
          bg="rgba(0, 0, 0, 0.7)" // Set the background color with low opacity for the overlay
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          left="0"
        />
        <Flex
          h="100%"
          justify="center"
          align="center"
          position="relative"
          zIndex="1" // Ensure the content appears above the overlay
          flexDirection="column"
          color="white"
        >
          <Text fontSize="3xl" textAlign="center" pb={4}>
            Revolutionary Washroom Solutions <span style={{ color: '#FFA500',fontSize: '36px', fontWeight:'500'}}>EGRESAN AUTTOGOES</span>

          </Text>
          <Heading as="h1" size="2xl" mb="4" textAlign="center">
            Transforming Public Washrooms for Better Hygiene
          </Heading>
          <Text fontSize="xl" textAlign="center" maxW="800px">
            Innovative, Fresh, Hygienic, and High-Tech Technology for Cost-Effective Interactions without Chemically Sanitized.
          </Text>
          <Button colorScheme="blue" size="lg" mt="8" onClick={handleToggle}>
              {isOpen ? '...Learn Less' : 'Learn More...'}
          </Button>
        </Flex>
      </Box>

      {/* Main Content Section */}
      <Collapse in={isOpen}>
      <Box bg="blue.800" p="8" color="white" textAlign="center">
          {/* <Heading as="h2" size="xl" mb="4" fontFamily="heading" fontWeight="bold" color="white">

            Main Content
          </Heading> */}
          <Text fontSize={{ base: 'md', md: 'lg' }} lineHeight={{ base: '1.6', md: '1.8' }}>
            Embrace the future of fresh and hygienic washrooms with EGRESAN tech, clean, and user-friendly economic
            interaction technology. Our innovative solution sets new standards of cleanliness while delivering
            cost-effectiveness. Experience the pinnacle of technology-driven hygiene.
          </Text>
          <Text>
            EGRESAN redefining cleanliness, affordability, and innovation in washroom experiences with every user count.
            We are dedicated to transforming public washrooms across India, addressing the pressing challenges that have
            deterred people from using these essential facilities. We understand that the condition of lavatories in public
            and crowded places has been a major concern, contributing to widespread apprehension and even open defecation
            in our country. Public toilets' lack of appropriate design and ventilation leads to unpleasant odors and
            suffocation, creating an inhospitable environment. Insufficient cleaning and sanitization further exacerbate
            the problem, turning these spaces into breeding grounds for dangerous pathogens and bacteria. The construction
            materials, often concrete and mortar, pose difficulties for effective cleaning, hampering efforts to maintain
            proper hygiene standards.
          </Text>
        </Box>
      </Collapse>


      {/* Mission Section */}
  {/* <Box bg="blue.100" p="8" color="blue.800" textAlign="center">
      <Heading as="h2" size="xl" mb="4" fontFamily="heading" fontWeight="bold" color="blue.800">
        Mission
      </Heading>
      <Text fontSize={{ base: 'md', md: 'lg' }} lineHeight={{ base: '1.6', md: '1.8' }}>
        Our mission is to revolutionize public washrooms with EGRESAN dry toilets, providing a refreshing, safe, and
        private experience for all. We are committed to enhancing accessibility, addressing hygiene concerns, and
        prioritizing the well-being of women and children. With our research-driven innovations and advanced
        technologies, we aim to create a new era of public washrooms that meet the highest standards of cleanliness,
        privacy, and safety.
      </Text>
      </Box> */}

      {/* Vision Section */}
      {/* <Box bg="blue.200" p="8" color="blue.800" textAlign="center">
      <Heading as="h2" size="xl" mb="4" fontFamily="heading" fontWeight="bold" color="blue.800">
        Vision
      </Heading>
      <Text fontSize={{ base: 'md', md: 'lg' }} lineHeight={{ base: '1.6', md: '1.8' }}>
        Our vision is to transform traditional restrooms into smart, efficient, and eco-friendly spaces, where users
        can enjoy unparalleled comfort and cleanliness. The Automated Vision Toilet aims to set new standards in the
        industry by combining advanced features, seamless automation, and data-driven insights to create a
        state-of-the-art restroom experience.
      </Text>
      </Box> */}
      
    </Box>
  );
};

export default Hero;
