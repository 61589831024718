
import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';

import sanjeevaniImage from '../../assets/WhatsApp Image 2023-12-18 at 10.42.14 PM.jpeg';
import narendraImage from '../../assets/profile/Narendra.jpeg';
import mangeshImage from '../../assets/profile/Mangesh.jpeg';


const teamMembers = [
  {
    name: 'Sanjeevani Gondane',
    role: 'Founder and CEO',
    image: sanjeevaniImage,
  },
  {
    name: 'Narendra Tidke',
    role: 'Director',
    image: narendraImage,
  },
  {
    name:'Mr. Mangesh Jambhulkar',
    role:'Director',
    image: mangeshImage,
  },
];
 
const Members = () => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();

  const handleScroll = () => {
    const teamSection = document.getElementById('team');
    if (teamSection) {
      const rect = teamSection.getBoundingClientRect();
      setIsVisible(rect.top < window.innerHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 30 });
    }
  }, [isVisible, controls]);

  return (
    <>
      <Box id="team" py="10"  bgGradient="linear(to top, #a5c9fb, #d6eaff)" mt="4">
      <Heading as="h2" size="xl" textAlign="center" mb="8" color="blue.800" mt={8}>
        Our Team
      </Heading>
      <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
        {teamMembers.map((member, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 30 }}
            animate={controls}
            transition={{ duration: 0.6, delay: index * 0.2 }}
            style={{ width: '300px', margin: '16px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
          >
            <Image src={member.image} alt={member.name} borderRadius="8px 8px 0 0" w="100%" h="300px" objectFit="cover" />
            <Box p="4" textAlign="center">
              <Heading size="md" mb="2" fontSize={"20px"} color="blue.800">
                {member.name}
              </Heading>
              <Text fontSize="md" fontWeight="bold" opacity="0.8" color="blue.800">
                {member.role}
              </Text>
            </Box>
          </motion.div>
        ))}
      </Flex>
      </Box>
    </>
  );
};

export default Members;
