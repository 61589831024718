import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';

const teamMembers = [
  {
    name: 'Sanjeevani Gondane',
    role: 'Founder and CEO',
    image: 'https://via.placeholder.com/150', 
  },
  {
    name: 'Narendra Tidke',
    role: 'Director',
    image: 'https://via.placeholder.com/150',
  },
  {
    name:'Alok Pathak',
    role:'Operation Head',
    image:'https://via.placeholder.com/150'
  },
  {
    name:'Sushil Umredkar',
    role:'Automation  Head',
    image:'https://via.placeholder.com/150'
  },
  {
    name: 'Avinash Kumar',
    role: 'Software Engineer',
    image: 'https://via.placeholder.com/150', // Replace with the actual image URL
  },
];

const Team = () => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();

  const handleScroll = () => {
    const teamSection = document.getElementById('team');
    if (teamSection) {
      const rect = teamSection.getBoundingClientRect();
      setIsVisible(rect.top < window.innerHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 30 });
    }
  }, [isVisible, controls]);

  return (
    <>
      <Box id="team" py="10" bgGradient="linear(to top, #a5c9fb, #d6eaff)" mt="4">
        <Heading as="h2" size="xl" textAlign="center" mb="8" color="blue.800" mt={8}>
          Our Team
        </Heading>
        <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
          {teamMembers.map((member, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 30 }}
              animate={controls}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              style={{ width: '300px', margin: '16px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
            >
              <Image src={member.image} alt={member.name} borderRadius="8px 8px 0 0" w="100%" h="200px" objectFit="cover" />
              <Box p="4" textAlign="center">
                <Heading size="md" mb="2" fontSize="24px" color="blue.800">
                  {member.name}
                </Heading>
                <Text fontSize="md" fontWeight="bold" opacity="0.8" color="blue.800">
                  {member.role}
                </Text>
              </Box>
            </motion.div>
          ))}
        </Flex>
      </Box>

      {/* Additional Content */}
      <Box py="8" bg="blue.100" textAlign="center" color="blue.800">
        <Heading as="h3" size="lg" mb="4" fontFamily="heading" fontWeight="bold">
          Meet Our Dynamic Team
        </Heading>
        <Text fontSize="lg" lineHeight="1.6" px="8">
          Our team of dedicated professionals brings together diverse skills and expertise to drive innovation and
          excellence. With a shared passion for revolutionizing public washrooms, we are committed to providing the
          best user experience, ensuring the highest standards of cleanliness, safety, and hygiene. Together, we are
          proud to be shaping the future of washroom technology.
        </Text>
      </Box>

      <Box py="8" bg="blue.200" textAlign="center" color="blue.800">
        <Heading as="h3" size="lg" mb="4" fontFamily="heading" fontWeight="bold">
          Our Team's Expertise
        </Heading>
        <Text fontSize="lg" lineHeight="1.6" px="8">
          Each member of our team brings a unique set of skills and expertise to the table. From technical
          proficiency in automation and software engineering to visionary leadership and strategic planning, our team
          covers every aspect of the EGRESAN mission. We collaborate and innovate, constantly pushing the boundaries
          of restroom technology to deliver a seamless user experience.
        </Text>
      </Box>
    </>
  );
};

export default Team;
