import React from 'react'
import Hero from './Hero'
import Product from './Product'
import Aim from './Aim'
import Members from './Members'

const HomePage = () => {
  return (
    <>
        <Hero/>
        <Product/>
        <Aim/>
        <Members/>
    </>
  )
}

export default HomePage