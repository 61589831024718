import React from 'react';
import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react';

const Aim = () => {
  return (
    <>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        {/* Mission Section */}
        <Box
          bgGradient="linear(to-r, blue.500, blue.800)"
          p="8"
          color="white"
          textAlign="center"
          borderRadius="lg"
          boxShadow="md"
          mt="4"
          mx="4"
          opacity="0.9"
        >
          <Heading as="h2" size="xl" mb="4" fontFamily="heading" fontWeight="bold">
            Our Mission
          </Heading>
          <Text fontSize={{ base: 'md', md: 'lg' }} lineHeight={{ base: '1.6', md: '1.8' }}>
            Our mission is to revolutionize public washrooms with EGRESAN dry toilets, providing a refreshing, safe, and
            private experience for all. We are committed to enhancing accessibility, addressing hygiene concerns, and
            prioritizing the well-being of women and children. With our research-driven innovations and advanced
            technologies, we aim to create a new era of public washrooms that meet the highest standards of cleanliness,
            privacy, and safety.
          </Text>
        </Box>

        {/* Vision Section */}
        <Box
          bgGradient="linear(to-r, blue.300, blue.600)"
          p="8"
          color="white"
          textAlign="center"
          borderRadius="lg"
          boxShadow="md"
          mt="4"
          mx="4"
          opacity="0.9"
        >
          <Heading as="h2" size="xl" mb="4" fontFamily="heading" fontWeight="bold">
            Our Vision
          </Heading>
          <Text fontSize={{ base: 'md', md: 'lg' }} lineHeight={{ base: '1.6', md: '1.8' }}>
            Our vision is to transform traditional restrooms into smart, efficient, and eco-friendly spaces, where users
            can enjoy unparalleled comfort and cleanliness. The Automated Vision Toilet aims to set new standards in the
            industry by combining advanced features, seamless automation, and data-driven insights to create a
            state-of-the-art restroom experience.
          </Text>
        </Box>
      </Flex>
    </>
  );
};

export default Aim;
