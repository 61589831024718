import React, { useState } from 'react';
import {
  Box,
  Flex,
  Link,
  Image,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { MdMenu, MdClose } from 'react-icons/md';

import logo from '../assets/egresan-logo.png'; 

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMobile, setIsMobile] = useState(false);

  // Detect mobile screen width
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    // remove event listener to avoid memory leaks
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuItemClick = () => {
    if (isMobile) {
      onClose();
    }
  };

  return (
    <Box bg="blue.500" p="4" color="white" justifyContent="space-between" width="100%">
      <Flex alignItems="center" justifyContent={"space-between"}>
        <Image src={logo} alt="Logo" h="50px" mr="4" borderRadius={"10px"} boxShadow="lg"/>
        <Box>
          {isMobile ? (
            <IconButton
              icon={isOpen ? <MdClose /> : <MdMenu />}
              variant="styled"
              aria-label="Mobile Menu"
              onClick={isOpen ? onClose : onOpen}
            />
          ) : (
            <>
              <Link as={RouterLink} to="/" boxShadow="lg" display="inline-block" bg="blue.500" color="white" px="4" py="2" mr="2" rounded="md" _hover={{ bg: 'blue.600' }} _focus={{ outline: 'none', boxShadow: 'outline' }}>
                Home
              </Link>
              <Link as={RouterLink} to="/product-details" boxShadow="lg" display="inline-block" bg="blue.500" color="white" px="4" py="2" mr="2" rounded="md" _hover={{ bg: 'blue.600' }} _focus={{ outline: 'none', boxShadow: 'outline' }}>
                Product Details
              </Link>
              <Link as={RouterLink} to="/team" boxShadow="lg" display="inline-block" bg="blue.500" color="white" px="4" py="2" mr="2" rounded="md" _hover={{ bg: 'blue.600' }} _focus={{ outline: 'none', boxShadow: 'outline' }}>
                Team
              </Link>
              <Link as={RouterLink} to="/about" boxShadow="lg" display="inline-block" bg="blue.500" color="white" px="4" py="2" mr="2" rounded="md" _hover={{ bg: 'blue.600' }} _focus={{ outline: 'none', boxShadow: 'outline' }}>
                About Us
              </Link>
              <Link as={RouterLink} to="/contact" boxShadow="lg" display="inline-block" bg="blue.500" color="white" px="4" py="2" mr="2" rounded="md" _hover={{ bg: 'blue.600' }} _focus={{ outline: 'none', boxShadow: 'outline' }}>
                Contact Us
              </Link>
            </>
          )}
        </Box>
      </Flex>
      {isMobile && (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent bg="blue.500" color="white" style={{maxWidth:"200px"}}>
            <DrawerHeader borderBottomWidth="1px">
              <IconButton icon={<MdClose />} variant="styled" aria-label="Close Menu" onClick={onClose} />
            </DrawerHeader>
            <DrawerBody>
              <Link as={RouterLink} to="/" display="block" my="2" onClick={handleMenuItemClick}>
                Home
              </Link>
              <Link as={RouterLink} to="/product-details" display="block" my="2" onClick={handleMenuItemClick}>
                Product details
              </Link>
              <Link as={RouterLink} to="/team" display="block" my="2" onClick={handleMenuItemClick}>
                Team
              </Link>
              <Link as={RouterLink} to="/about" display="block" my="2" onClick={handleMenuItemClick}>
                About us
              </Link>
              <Link as={RouterLink} to="/contact" display="block" my="2" onClick={handleMenuItemClick}>
                Contact Us
              </Link>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};

export default Navbar;
