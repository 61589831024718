import React from 'react';
import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaCheckCircle } from 'react-icons/fa';
import productImage from "../../assets/egresan-home.png"

const features = [
  {
    icon: FaCheckCircle,
    feature:'Touchless Operation: ',
    text: 'Advanced sensors for a completely touchless experience.',
  },
  {
    icon: FaCheckCircle,
    feature:'Personalized User Profiles: ',
    text: 'Customized settings for individual comfort.',
  },
  {
    icon: FaCheckCircle,
    feature:'Smart Lid and Seat: ',
    text: 'Automatic opening and closing mechanisms.',
  },
  {
    icon: FaCheckCircle,
    feature:'Intelligent Flushing System: ',
    text: 'Adapts to user needs to reduce water wastage.',
  },
  {
    icon: FaCheckCircle,
    feature:'Built-in Bidet and Air Drying: ',
    text: 'Adjustable water pressure and temperature.',
  },
  {
    icon: FaCheckCircle,
    feature:'Automated Cleaning and Maintenance: ',
    text: 'Self-cleaning capabilities and maintenance alerts.',
  },
  {
    icon: FaCheckCircle,
    feature:'Accessibility Features: ',
    text: 'Inclusive design for people with disabilities.',
  },
];

const Product = () => {
  return (
    <>
      <Box py="10" p="4" bgGradient="linear(to bottom, #f3f8ff, #a5c9fb)" position="relative" mt="4">
      {/* two line */}
      <Box
        position="absolute"
        top="5px"
        width="150px"
        height="3px"
        bg="green.300"
        my="2"
      ></Box>
      <Box
        position="absolute"
        top="20px"
        // left="50%"
        // transform="translateX(-35px)"
        width="250px"
        height="3px"
        bg="blue.500" // Change color to your choice
        my="2"
      ></Box>
      {/* lines end */}
      <Heading as="h2" size="xl" textAlign="center" mb="8" mt={8} color="blue.800" marginTop={8}>
        Product Details
      </Heading>
      <Flex justifyContent="center" alignItems="center" flexDirection={{ base: 'column', md: 'row' }}>
        <Box maxW={{ base: '100%', md: '40%' }}>
          <Image
            src={productImage}
            alt="Automated Vision Toilet"
            w="100%"
            borderRadius="8px"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            mb="4"
            p={{base:"4",md:"0"}}
          />
        </Box>
        <Box maxW={{ base: '100%', md: '60%' }} ml={{ base: 0, md: '8' }}>
          <Text fontSize={{ base: 'lg', md: 'xl' }} mb="4" justifyContent="center">
            With the Automated Vision Toilet, we aim to elevate the restroom experience for individuals, businesses, and
            society as a whole. This futuristic fixture exemplifies our commitment to innovation, sustainability, and
            user-centric design, setting the benchmark for smart restrooms of the future. At EGRESAN, we are proud to
            contribute to a cleaner, safer, and more convenient world, one restroom at a time.
          </Text>
          <Text fontSize={{ base: 'lg', md: 'xl' }} mb="4">
          <Heading as="h3" fontSize="24px" textAlign="center" mb="4" color={"blue.500"}>
            Key Features
          </Heading>
            <ul style={{ listStyleType: 'none' }}>
              {features.map((feature_, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: index * 0.2 }}
                  style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
                >
                  <Box as={feature_.icon} mr="2" color="blue.500" size="18px" />
                  <Text fontSize={{ base: 'md', md: 'lg' }}><span style={{fontWeight:"bold",opacity:"0.8"}}>{feature_.feature}</span>{feature_.text}</Text>
                </motion.li>
              ))}
            </ul>
          </Text>
        </Box>
      </Flex>
      </Box>



    </>
  );
};

export default Product;
