import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage/HomePage';
import ProductDetails from './pages/ProductDetails';
import Team from './pages/Team';
import About from './pages/About';
import Contact from './pages/Contact';


const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/product-details" element={<ProductDetails/>} />
        <Route path="/team" element={<Team/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/about" element={<About/>} />
      </Routes>
    </>
  );
};

export default AllRoutes;
