import React from 'react';

const WavePattern = () => {
  return (
    <svg
      width="100%"
      height="100%"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="none"
      viewBox="0 0 100 100"
      style={{ position: 'absolute', zIndex: '-1' }}
    >
      {/* Customize the path and fill color here */}
      <path
        d="M0 0 C 20 50 40 50 100 80 L 100 0 Z"
        fill="#7FC3FF"
      />
    </svg>
  );
};

export default WavePattern;
